<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 240 400"
  ><path
    fill="currentColor"
    d="M112 0C94 0 80 14 80 32v22c0 18-14 32-32 32H32c-18 0-32 14-32 32v16c0 18 14 32 32 32h16c18 0 32 14 32 32v90c0 18-14 32-32 32H32c-18 0-32 14-32 32v16c0 18 14 32 32 32h176c18 0 32-14 32-32v-16c0-18-14-32-32-32h-16c-18 0-32-14-32-32V32c0-18-14-32-32-32h-16Z"
  /></svg>
</template>
